import http from "../../../utils/http";
import { API_BASE_IKK_ADMIN, API_ENDPOINT } from "../../../utils/routes";
import {
  CONST_PUBLIC_INFO_CATEGORY_CREATE_BEGIN,
  CONST_PUBLIC_INFO_CATEGORY_CREATE_FAILURE,
  CONST_PUBLIC_INFO_CATEGORY_CREATE_SUCCESS,
  CONST_PUBLIC_INFO_CATEGORY_DELETE_BEGIN,
  CONST_PUBLIC_INFO_CATEGORY_DELETE_FAILURE,
  CONST_PUBLIC_INFO_CATEGORY_DELETE_SUCCESS,
  CONST_PUBLIC_INFO_CATEGORY_GET_BEGIN,
  CONST_PUBLIC_INFO_CATEGORY_GET_FAILURE,
  CONST_PUBLIC_INFO_CATEGORY_GET_SUCCESS,
  CONST_PUBLIC_INFO_CATEGORY_POSITION_BEGIN,
  CONST_PUBLIC_INFO_CATEGORY_POSITION_FAILURE,
  CONST_PUBLIC_INFO_CATEGORY_POSITION_SUCCESS,
  CONST_PUBLIC_INFO_ITEM_DELETE_BEGIN,
  CONST_PUBLIC_INFO_ITEM_DELETE_FAILURE,
  CONST_PUBLIC_INFO_ITEM_DELETE_SUCCESS,
  CONST_PUBLIC_INFO_ITEM_GET_BEGIN,
  CONST_PUBLIC_INFO_ITEM_GET_FAILURE,
  CONST_PUBLIC_INFO_ITEM_GET_SUCCESS,
  CONST_PUBLIC_INFO_ITEM_POSITION_BEGIN,
  CONST_PUBLIC_INFO_ITEM_POSITION_FAILURE,
  CONST_PUBLIC_INFO_ITEM_POSITION_SUCCESS,
} from "../../actionTypes";
import { addToastDanger, addToastSuccess } from "../common/toast";

export const getItems = () => async (dispatch) => {
  dispatch(getItemsBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.PUBLIC_INFO_ITEMS);
    dispatch(getItemsSuccess(json));
    // return json;
  } catch (error) {
    dispatch(getItemsFailure(error));
    dispatch(addToastDanger(`Adatok betöltése sikertelen: ${error}`));
  }
};

export const getCategory = () => async (dispatch) => {
  dispatch(getCategoryBegin());
  try {
    const json = await http.get(API_BASE_IKK_ADMIN + API_ENDPOINT.PUBLIC_INFO_CATEGORY);
    dispatch(getCategorySuccess(json));
    // return json;
  } catch (error) {
    dispatch(getCategoryFailure(error));
    dispatch(addToastDanger(`Kategóriák betöltése sikertelen: ${error}`));
  }
};

export const changeItemPosition = (id, new_pos) => async (dispatch) => {
  dispatch(changeItemPositionBegin());

  try {
    const json = await http.get(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_ITEM_POSTION}/${id}/${new_pos}`
    );
    dispatch(changeItemPositionSuccess(json));
    dispatch(addToastSuccess("Elem poziciója módosítva."));
    return json;
  } catch (error) {
    dispatch(changeItemPositionFail(error));
    dispatch(addToastDanger(`Elem pozíció módosítás sikertelen: ${error}`));
  }
};

export const changeCategoryPosition = (id, new_pos) => async (dispatch) => {
  console.log("changeCategoryPosition");
  dispatch(changeCategoryPositionBegin());
  try {
    const json = await http.get(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_CATEGORY_POSTION}/${id}/${new_pos}`
    );
    dispatch(changeCategoryPositionSuccess(json));
    dispatch(addToastSuccess("Kategória pozició módosítva."));
    return json;
  } catch (error) {
    dispatch(changeCategoryPositionFail(error));
    dispatch(addToastDanger(`Kategória pozíció módosítás sikertelen: ${error}`));
    console.log("error:", error);
  }
};

export const createCategory = (name, type, parent_cat = null) => async (dispatch) => {
  dispatch(createCategoryBegin());
  try {
    const json = await http.post(API_BASE_IKK_ADMIN + API_ENDPOINT.PUBLIC_INFO_CATEGORY, {
      name,
      type,
      parent_cat,
      visible: false,
    });
    dispatch(createCategorySuccess(json));
    dispatch(addToastSuccess("Kategória létrehozva"));
    return json;
  } catch (error) {
    dispatch(createCategoryFailure(error));
    dispatch(addToastDanger(`Kategória létrehozása sikertelen: ${error}`));
  }
};

export const modifyCategory = (id, name, visible, deep_link) => async (dispatch) => {
  dispatch(createCategoryBegin());
  try {
    const json = await http.put(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_CATEGORY}/${id}`,
      {
        name,
        visible: visible,
        deep_link: deep_link,
      }
    );
    dispatch(createCategorySuccess(json));
    dispatch(addToastSuccess("Kategória módosítás sikeres"));
  } catch (error) {
    dispatch(createCategoryFailure(error));
    dispatch(addToastDanger(`Kategória módosítás sikertelen: ${error}`));
  }
};

export const createItem = (name, media_id, category_id, visible) => async (dispatch) => {
  dispatch(createCategoryBegin());
  try {
    const json = http.post(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_CATEGORY}/${category_id}`,
      {
        name: name,
        media_id: media_id,
        visible: visible,
      }
    );
    dispatch(createCategorySuccess(json));
    dispatch(addToastSuccess("Közérdekű adat létrehozva"));
  } catch (error) {
    dispatch(createCategoryFailure(error));
    dispatch(addToastDanger(`Adat létrehozása sikertelen: ${error}`));
  }
};

export const modifyItem = (id, name, media_id, visible) => async (dispatch) => {
  dispatch(createCategoryBegin());
  try {
    const json = http.put(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_ITEMS}/${id}`,
      {
        name: name,
        media_id: media_id,
        visible: visible,
      }
    );
    dispatch(createCategorySuccess(json));
    dispatch(addToastSuccess("Közérdekű adat módosítva"));
  } catch (error) {
    dispatch(createCategoryFailure(error));
    dispatch(addToastDanger(`Adat módosítása sikertelen: ${error}`));
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  dispatch(deleteCategoryBegin());
  try {
    const json = await http.del(
      `${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_CATEGORY}/${id}`
    );
    dispatch(deleteCategorySuccess(json));
    dispatch(addToastSuccess("Kategória törölve"));
  } catch (error) {
    dispatch(deleteCategoryFailure(error));
    dispatch(addToastDanger(`Kategória törlés sikertelen: ${error}`));
  }
};

export const deleteItem = (id) => async (dispatch) => {
  dispatch(deleteItemBegin());
  try {
    const json = http.del(`${API_BASE_IKK_ADMIN}${API_ENDPOINT.PUBLIC_INFO_ITEMS}/${id}`);
    dispatch(deleteItemSuccess(json));
    dispatch(addToastSuccess("Dokumentum törölve"));
  } catch (error) {
    dispatch(deleteItemFailure(error));
    dispatch(addToastDanger(`Dokumentum törlés sikertelen: ${error}`));
  }
};

export const getItemsBegin = () => ({
  type: CONST_PUBLIC_INFO_ITEM_GET_BEGIN,
});
export const getItemsSuccess = (items) => ({
  type: CONST_PUBLIC_INFO_ITEM_GET_SUCCESS,
  payload: items,
});
export const getItemsFailure = (err) => ({
  type: CONST_PUBLIC_INFO_ITEM_GET_FAILURE,
  payload: { err },
});

export const getCategoryBegin = () => ({
  type: CONST_PUBLIC_INFO_CATEGORY_GET_BEGIN,
});
export const getCategorySuccess = (items) => ({
  type: CONST_PUBLIC_INFO_CATEGORY_GET_SUCCESS,
  payload: items,
});
export const getCategoryFailure = (err) => ({
  type: CONST_PUBLIC_INFO_CATEGORY_GET_FAILURE,
  payload: { err },
});
export const createCategoryBegin = () => ({
  type: CONST_PUBLIC_INFO_CATEGORY_CREATE_BEGIN,
});

export const createCategorySuccess = (category) => {
  return {
    type: CONST_PUBLIC_INFO_CATEGORY_CREATE_SUCCESS,
    payload: { category },
  };
};
export const createCategoryFailure = (error) => ({
  type: CONST_PUBLIC_INFO_CATEGORY_CREATE_FAILURE,
  payload: { error },
});
export const deleteCategoryBegin = () => ({
  type: CONST_PUBLIC_INFO_CATEGORY_DELETE_BEGIN,
});
export const deleteCategorySuccess = (category) => {
  return {
    type: CONST_PUBLIC_INFO_CATEGORY_DELETE_SUCCESS,
    payload: { category },
  };
};
export const deleteCategoryFailure = (error) => ({
  type: CONST_PUBLIC_INFO_CATEGORY_DELETE_FAILURE,
  payload: { error },
});

export const deleteItemBegin = () => ({
  type: CONST_PUBLIC_INFO_ITEM_DELETE_BEGIN,
});

export const deleteItemSuccess = (category) => {
  return {
    type: CONST_PUBLIC_INFO_ITEM_DELETE_SUCCESS,
    payload: { category },
  };
};

export const deleteItemFailure = (error) => ({
  type: CONST_PUBLIC_INFO_ITEM_DELETE_FAILURE,
  payload: { error },
});

const changeCategoryPositionBegin = () => ({
  type: CONST_PUBLIC_INFO_CATEGORY_POSITION_BEGIN,
});
const changeCategoryPositionSuccess = (res) => ({
  type: CONST_PUBLIC_INFO_CATEGORY_POSITION_SUCCESS,
  payload: res,
});
const changeCategoryPositionFail = (error) => ({
  type: CONST_PUBLIC_INFO_CATEGORY_POSITION_FAILURE,
  payload: { error },
});
const changeItemPositionBegin = () => ({
  type: CONST_PUBLIC_INFO_ITEM_POSITION_BEGIN,
});
const changeItemPositionSuccess = (res) => ({
  type: CONST_PUBLIC_INFO_ITEM_POSITION_SUCCESS,
  payload: res,
});
const changeItemPositionFail = (error) => ({
  type: CONST_PUBLIC_INFO_ITEM_POSITION_FAILURE,
  payload: { error },
});
